/* styles.css */
.round-checkbox {
    width:  15px;
    height: 15px;
    border-radius: 20%;
    -webkit-appearance: none;
    outline: none;
    cursor: pointer;
    border: 2px solid #060506;
    background-color: #fff;
  }
  
.round-checkbox:checked {
    background-color: #5f6265; /* Tailwind's gray-400 */
}

.round-checkbox:checked:after {
    content: "";
    width: 10px;
    height: 10px;
    display: block;
    border-radius: 20%;
    background-color: #5f6265; /* Tailwind's gray-400 */
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
